import { useState } from "react";

const ProjectTabButton = ({ data, showActiveTabContent, onclick }) => {
  const [activeTab, setActiveTab] = useState(0);

  const makeTabActive = (id) => {
    setActiveTab(id);
  };

  return data.map((item, index) => (
    <span
      className={index === activeTab ? "active" : ""}
      data-id={`#tab_${index}`}
      onClick={() => {
        onclick();
        showActiveTabContent(index);
        makeTabActive(index);
      }}
      key={index}
    >
      {item.title}
    </span>
  ));
};

export default ProjectTabButton;
