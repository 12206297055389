import "./App.css";
import React, { useEffect, useState } from "react";
import logo from "./img/tingX2.png";
import { request } from "graphql-request";
import ProjectTabButton from "./components/projectTabButtons";
import ProjectItem from "./components/projectItem";
import copyIcon from "./img/icons8-copy-48.png";
import useWindowSize from "./Hooks/useWindowSize";

function App() {
  const [data, setData] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [copiedText, setCopiedText] = useState("Copy Link");
  const [currentTab, setCurrentTab] = useState("Qode Corp");
  const [storedArray, setStoredArray] = useState([]);
  const size = useWindowSize();

  useEffect(() => {
    const query = `
    {
     titles {
       title
       records {
         image {
           url
           width
           height
         }
         link
         name
         filter {
           title
         }
       }
     }
   }
  `;
    request(
      "https://api-ap-south-1.hygraph.com/v2/cldekz88i1acf01uggkjycy15/master",
      query
    ).then((result) => {
      setData(result?.titles);
    });
  }, []);

  const showActiveTabContent = (tabId) => {
    setActiveTab(tabId);
  };

  const selecOnChange = (e) => {
    setCopiedText();
    let arrayData = data[activeTab].records;
    setStoredArray(
      arrayData.filter((el) => el.filter?.title === e.target.value && el)
    );
  };

  const onClickTabButton = () => {
    let select = document.querySelector(".filter");
    if (select.value !== `Filter`) {
      select.selectedIndex = 0;
    }
  };

  return (
    <div className="main-wrapper">
      <div className="ting-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="tab">
        <div className="button_section">
          <h5>
            {data && (
              <ProjectTabButton
                onclick={onClickTabButton}
                data={data}
                showActiveTabContent={showActiveTabContent}
              />
            )}
          </h5>
          {size.width > 1000 && (
            <select className="filter" onChange={(e) => selecOnChange(e)}>
              <option value="Filter">Filter</option>
              {data &&
                Array.from(
                  new Set(
                    data[activeTab].records?.map((el) => el.filter?.title)
                  )
                )?.map((el, id) => (
                  <option value={el?.title} key={id}>
                    {el}
                  </option>
                ))}
            </select>
          )}
        </div>
        {size.width < 1000 && (
          <select className="filter" onChange={(e) => selecOnChange(e)}>
            <option value="Filter">Filter</option>
            {data &&
              Array.from(
                new Set(data[activeTab].records?.map((el) => el.filter?.title))
              )?.map((el, id) => (
                <option value={el?.title} key={id}>
                  {el}
                </option>
              ))}
          </select>
        )}
        {data &&
          document.querySelector(".filter")?.value === "Filter" &&
          data?.map((currElem, id) => (
            <div
              className={id === activeTab ? "categories active" : "categories"}
              id={`tab_${id}`}
              key={id}
            >
              <div className="logo-section">
                {currElem.records?.map((project, id) => (
                  <ProjectItem
                    key={id}
                    cat={currElem?.title}
                    project={project}
                    index={id}
                    copiedText={copiedText === id ? "Copied" : "Copy Link"}
                    setCopiedText={setCopiedText}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                  />
                ))}
              </div>
            </div>
          ))}

        {data &&
          document.querySelector(".filter")?.value !== "Filter" &&
          data?.map((el, id) => (
            <div
              className={id === activeTab ? "categories active" : "categories"}
              id={`tab_${id}`}
              key={id}
            >
              <div className="logo-section">
                {storedArray?.map((currElem, id) => (
                  <div className="logo-inside" key={id}>
                    <a href={currElem.link} target="_blank" rel="noreferrer">
                      <div className="logo-img">
                        <img
                          className="img-responsive"
                          src={currElem.image.url}
                          alt=""
                        />
                      </div>
                    </a>
                    <div className="content">
                      <p>{currElem.name}</p>
                      <div className="tooltip">
                        <img
                          src={copyIcon}
                          alt=""
                          onClick={() => [
                            setCurrentTab(storedArray?.title),
                            setCopiedText(id),
                            navigator.clipboard.writeText(currElem.link),
                          ]}
                        />
                        <span
                          className="tooltiptext"
                          style={{
                            backgroundColor:
                              copiedText === id ? "#04d523" : "black",
                          }}
                        >
                          {copiedText === id ? "Copied" : "Copy Link"}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default App;
